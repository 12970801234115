"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isResource = exports.isRelative = exports.isRolePolicy = exports.isRole = exports.isRegistrationDomain = exports.isRegistrationCode = exports.isProof = exports.isProduct = exports.isOutputTask = exports.isOutlet = exports.isObjectivesView = exports.isItemUnit = exports.isItemContext = exports.isItemCollection = exports.isItem = exports.isIssue = exports.isFile = exports.isFavorite = exports.isEventLogItem = exports.isExamUnit = exports.isExamTicket = exports.isExamSession = exports.isExamResponse = exports.isExamConfigSession = exports.isExamExamConfig = exports.isExamConfig = exports.isExam = exports.isCourseVerification = exports.isCourseResource = exports.isCoursePlan = exports.isCourseCalendar = exports.isCourse = exports.isContextItem = exports.isComment = exports.isCollection = exports.isClassroomUser = exports.isClassroomUnit = exports.isClassroomActivity = exports.isClassroomPlannerItem = exports.isClassroom = exports.isBlueprint = exports.isBank = exports.isAnnotation = exports.isAlignmentDocument = exports.isAppUserConfig = exports.isAppLicense = exports.isAppKey = exports.isApp = exports.isAcademicCalendar = exports.isIDSK = void 0;
exports.isContentRecord = exports.isFavoriteRecord = exports.isAppUserRecord = exports.isAppRecord = exports.isBankRecord = exports.isWorkspaceUserRecord = exports.isWorkspaceRecord = exports.isLogRecord = exports.isClassroomRecord = exports.isWorkspaceUser = exports.isWorkspaceShare = exports.isWorkspaceParent = exports.isWorkspaceInvitation = exports.isWorkspace = exports.isVocabulary = exports.isVendorId = exports.isUserNotificationUnread = exports.isUserNotification = exports.isUserClassroom = exports.isUser = exports.isUnitResource = exports.isUnitItem = exports.isUnitExam = exports.isUnit = exports.isTaskResource = exports.isTask = exports.isSystemUpdateExamItemStats = exports.isStudent = exports.isResourceUnit = exports.isResourceTask = exports.isResourceCourse = void 0;
const isIDSK = (obj) => typeof (obj === null || obj === void 0 ? void 0 : obj.id) === "string" && typeof (obj === null || obj === void 0 ? void 0 : obj.sk) === "string";
exports.isIDSK = isIDSK;
/**
 * Higher-order function to create a generic type constraint function
 * that can handle subtypes like Parsed* etc
 *
 * So that you can have something like:
 *
 *  const wsu: ParsedWorkspaceUser | Workspace = getWorkspaceOrUser();
 *  if(isWorkspaceUser(wsu)) {
 *    // types for `wsu.acl` are correct based on the ParsedWorkspaceUser type
 *    console.log(wsu.acl.role);
 *  }
 */
const createSkTypeCheck = (sk, opts) => (obj) => (opts === null || opts === void 0 ? void 0 : opts.exact) ? (obj === null || obj === void 0 ? void 0 : obj.sk) === sk : !!(obj === null || obj === void 0 ? void 0 : obj.sk.startsWith(sk));
exports.isAcademicCalendar = createSkTypeCheck("academic-calendar#v");
exports.isApp = createSkTypeCheck("app", { exact: true });
exports.isAppKey = createSkTypeCheck("app-key", { exact: true });
exports.isAppLicense = createSkTypeCheck("app-license#");
exports.isAppUserConfig = createSkTypeCheck("app-user-config#");
exports.isAlignmentDocument = createSkTypeCheck("alignment#v");
exports.isAnnotation = createSkTypeCheck("annotation#");
exports.isBank = createSkTypeCheck("bank#");
exports.isBlueprint = createSkTypeCheck("blueprint#v");
exports.isClassroom = createSkTypeCheck("classroom", {
    exact: true,
});
exports.isClassroomPlannerItem = createSkTypeCheck("classroom-planner-item:");
exports.isClassroomActivity = createSkTypeCheck("classroom-activity#");
exports.isClassroomUnit = createSkTypeCheck("classroom-unit#");
exports.isClassroomUser = createSkTypeCheck("classroom-user#");
exports.isCollection = createSkTypeCheck("collection#v0");
exports.isComment = createSkTypeCheck("comment#");
exports.isContextItem = createSkTypeCheck("context-item:");
exports.isCourse = createSkTypeCheck("course#v0", { exact: true });
exports.isCourseCalendar = createSkTypeCheck("course-calendar#");
exports.isCoursePlan = createSkTypeCheck("course-plan#v");
exports.isCourseResource = createSkTypeCheck("course-resource:");
exports.isCourseVerification = createSkTypeCheck("course-verification#v");
exports.isExam = createSkTypeCheck("exam#v");
exports.isExamConfig = createSkTypeCheck("exam-config", {
    exact: true,
});
exports.isExamExamConfig = createSkTypeCheck("exam-config:");
exports.isExamConfigSession = createSkTypeCheck("exam-config-session#");
exports.isExamResponse = createSkTypeCheck("exam-response:");
exports.isExamSession = createSkTypeCheck("exam-session", {
    exact: true,
});
exports.isExamTicket = createSkTypeCheck("exam-ticket", {
    exact: true,
});
exports.isExamUnit = createSkTypeCheck("exam-unit:");
exports.isEventLogItem = createSkTypeCheck("event-log-item#");
exports.isFavorite = createSkTypeCheck("favorite#");
exports.isFile = createSkTypeCheck("file#");
exports.isIssue = createSkTypeCheck("issue", { exact: true });
exports.isItem = createSkTypeCheck("item#v");
exports.isItemCollection = createSkTypeCheck("item-collection:");
exports.isItemContext = createSkTypeCheck("item-context:");
exports.isItemUnit = createSkTypeCheck("item-unit:");
exports.isObjectivesView = createSkTypeCheck("objectives-view#v");
exports.isOutlet = createSkTypeCheck("outlet#");
exports.isOutputTask = createSkTypeCheck("output-task:");
exports.isProduct = createSkTypeCheck("product", {
    exact: true,
});
exports.isProof = createSkTypeCheck("proof#");
exports.isRegistrationCode = createSkTypeCheck("registration-code#");
exports.isRegistrationDomain = createSkTypeCheck("registration-domain#");
exports.isRole = createSkTypeCheck("role", { exact: true });
exports.isRolePolicy = createSkTypeCheck("role-policy#");
exports.isRelative = createSkTypeCheck("relative:");
exports.isResource = createSkTypeCheck("resource#v");
exports.isResourceCourse = createSkTypeCheck("resource-course:");
exports.isResourceTask = createSkTypeCheck("resource-task:");
exports.isResourceUnit = createSkTypeCheck("resource-unit:");
exports.isStudent = createSkTypeCheck("student#");
const isSystemUpdateExamItemStats = (obj) => {
    return obj.id === "_system:update_exam_item_stats";
};
exports.isSystemUpdateExamItemStats = isSystemUpdateExamItemStats;
exports.isTask = createSkTypeCheck("task", { exact: true });
exports.isTaskResource = createSkTypeCheck("task-resource:");
exports.isUnit = createSkTypeCheck("unit#v0", { exact: true });
exports.isUnitExam = createSkTypeCheck("unit-exam:");
exports.isUnitItem = createSkTypeCheck("unit-item:");
exports.isUnitResource = createSkTypeCheck("unit-resource:");
exports.isUser = createSkTypeCheck("user", { exact: true });
exports.isUserClassroom = createSkTypeCheck("user-classroom#");
exports.isUserNotification = createSkTypeCheck("user-notification#");
exports.isUserNotificationUnread = createSkTypeCheck("user-notification-unread#");
exports.isVendorId = createSkTypeCheck("vendor-id#");
exports.isVocabulary = createSkTypeCheck("vocabulary", {
    exact: true,
});
exports.isWorkspace = createSkTypeCheck("workspace", {
    exact: true,
});
exports.isWorkspaceInvitation = createSkTypeCheck("invitation#");
exports.isWorkspaceParent = createSkTypeCheck("workspace-parent", {
    exact: true,
});
exports.isWorkspaceShare = createSkTypeCheck("workspace-share#");
exports.isWorkspaceUser = createSkTypeCheck("workspace-user#");
/**
 * The predicates belonw came over from cli/workspace:reset.
 *
 * Not doing a bunch of refactor on these yet, just trying to at least move all
 * predicates in the codebase to a single file.
 */
const isClassroomRecord = (item) => {
    if (item.sk.match(/^academic-calendar/))
        return true;
    if (item.sk.match(/^classroom$/))
        return true;
    if (item.sk.match(/^classroom-unit#/))
        return true;
    if (item.sk.match(/^classroom-user#/))
        return true;
    if (item.sk.match(/^course-calendar#/))
        return true;
    if (item.sk.match(/^user-classroom#/))
        return true;
};
exports.isClassroomRecord = isClassroomRecord;
const isLogRecord = (item) => {
    if (item.sk.match(/^event-log-item#/))
        return true;
    if (item.sk.match(/^revision-note#/))
        return true;
};
exports.isLogRecord = isLogRecord;
const isWorkspaceRecord = (item) => {
    if (item.sk.match(/^workspace$/))
        return true;
    if (item.sk.match(/^registration-code#/))
        return true;
};
exports.isWorkspaceRecord = isWorkspaceRecord;
const isWorkspaceUserRecord = (item) => {
    if (item.sk.match(/^workspace-user#/))
        return true;
    if (item.sk.match(/^role/))
        return true;
};
exports.isWorkspaceUserRecord = isWorkspaceUserRecord;
const isBankRecord = (item) => {
    if (item.sk.match(/^bank#/))
        return true;
};
exports.isBankRecord = isBankRecord;
const isAppRecord = (item) => {
    if (item.sk.match(/^app-key$/))
        return true;
    if (item.sk.match(/^app-license#/))
        return true;
    if (item.sk.match(/^app#/))
        return true;
    if (item.sk.match(/^app$/))
        return true;
};
exports.isAppRecord = isAppRecord;
const isAppUserRecord = (item) => {
    if (item.sk.match(/^app-user-config#/))
        return true;
};
exports.isAppUserRecord = isAppUserRecord;
const isFavoriteRecord = (item) => {
    if (item.sk.match(/^favorite#/))
        return true;
};
exports.isFavoriteRecord = isFavoriteRecord;
const isContentRecord = (item) => {
    if (item.sk.match(/^alignment#/))
        return true;
    if (item.sk.match(/^context-item:/))
        return true;
    if (item.sk.match(/^collection#v0$/))
        return true;
    if (item.sk.match(/^course/))
        return true;
    if (item.sk.match(/^exam#/))
        return true;
    if (item.sk.match(/^file#/))
        return true;
    if (item.sk.match(/^item-context:/))
        return true;
    if (item.sk.match(/^item-unit:/))
        return true;
    if (item.sk.match(/^item#v0/))
        return true;
    if (item.sk.match(/^objectives-view/))
        return true;
    if (item.sk.match(/^relative:/))
        return true;
    if (item.sk.match(/^resource-course/))
        return true;
    if (item.sk.match(/^resource-unit/))
        return true;
    if (item.sk.match(/^resource#v0/))
        return true;
    if (item.sk.match(/^unit/))
        return true;
    if (item.sk.match(/^vendor-id/))
        return true;
    if (item.sk.match(/^vocabulary/))
        return true;
};
exports.isContentRecord = isContentRecord;
